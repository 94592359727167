// action - state management
import * as actionTypes from './actions';

export const initialState = {
  id: null, // for active default menu
  username: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = initialState, action: { type: any; id: any; username: string }) => {
  let id;
  let username;
  switch (action.type) {
    case actionTypes.SET_USER:
      id = action.id;
      username = action.username;
      return {
        ...state,
        id: id,
        username
      };
    default:
      return state;
  }
};

export default userReducer;
