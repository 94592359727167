import { Alert, AlertTitle, Button, TextField } from '@mui/material';
import { PhoneMaskInput } from '../PhoneMaskInput';
import { FormContainer } from './styles';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';

interface Payload {
  name: string;
  username: string;
  birthdate: Dayjs;
  mobile_phone: string;
}

export function EditProfileForm() {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState<string>('');

  const submitForm = (values: Payload) => {
    const payload = {
      ...values,
      birthdate: values.birthdate.toISOString()
    };

    setLoading(true);

    api
      .put('Users/profile', payload)
      .catch(({ response }) => {
        setError(response.data.notifications[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearPhone = (phone: string) => {
    return phone.replaceAll(/\D/g, '');
  };

  const { handleSubmit, handleChange, setValues, resetForm, touched, errors, values } = useFormik<Payload>({
    initialValues: {
      name: '',
      username: '',
      birthdate: dayjs(),
      mobile_phone: ''
    },
    validationSchema,
    onSubmit: (values) => {
      submitForm({ ...values, mobile_phone: clearPhone(values.mobile_phone) });
    }
  });

  const { name, username, birthdate, mobile_phone } = values;

  useEffect(() => {
    setLoading(true);
    api
      .get('Authorize/user-data')
      .then(({ data }) => {
        const {
          data: { name, username, birthdate, mobile_phone, id }
        } = data;

        setUserId(id);

        setValues({
          name,
          username,
          birthdate: dayjs(birthdate),
          mobile_phone
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let timeout;

    if (error) {
      timeout = setTimeout(() => {
        setError('');
      }, 5000);
    } else {
      clearTimeout(timeout);
    }
  }, [error]);

  return (
    <form
      style={{
        width: '100%',
        maxWidth: '720px'
      }}
      onSubmit={handleSubmit}
    >
      <FormContainer>
        <TextField
          id="name"
          name="name"
          label="Nome"
          variant="outlined"
          value={name}
          onChange={handleChange}
          required
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
          disabled={loading}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            id="birthdate"
            name="birthdate"
            variant="outlined"
            label="Data de nascimento"
            value={birthdate}
            onChange={(value) => {
              if (value) {
                setValues({ ...values, birthdate: value });
              }
            }}
            format="DD/MM/YYYY"
            required
            helperText={touched.birthdate && !!errors.birthdate && String(errors.birthdate)}
            disabled={loading}
          />
        </LocalizationProvider>
        <TextField
          id="mobile_phone"
          name="mobile_phone"
          label="Número de telefone"
          variant="outlined"
          value={mobile_phone}
          onChange={handleChange}
          required
          InputProps={{
            inputComponent: PhoneMaskInput as any
          }}
          error={touched.mobile_phone && !!errors.mobile_phone}
          helperText={touched.mobile_phone && errors.mobile_phone}
          disabled={loading}
        />
        <TextField
          id="username"
          name="username"
          type="text"
          label="E-mail"
          variant="outlined"
          value={username}
          onChange={handleChange}
          required
          error={touched.username && !!errors.username}
          helperText={touched.username && errors.username}
          disabled={loading}
        />
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          sx={{
            width: 'max-content',
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem'
          }}
        >
          <SaveIcon />
          Salvar informações
        </Button>
      </FormContainer>
      {error && (
        <Alert
          severity="error"
          onClose={() => {
            setError('');
          }}
          sx={{
            marginTop: 2
          }}
        >
          <AlertTitle>
            <strong>Algo inesperado aconteceu</strong>
          </AlertTitle>
          {error?.split(':')[1].trim()}
        </Alert>
      )}
    </form>
  );
}
