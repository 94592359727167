import { useAuth } from 'context/AuthContext';
import { Navigate, useLocation } from 'react-router';

export type Role = 'ADMIN' | 'USER' | 'PARTNER' | 'OPERATOR' | 'FINANCIAL' | 'DISTRIBUTOR';

interface Props {
  children: JSX.Element;
  roles: Role[];
}

export default function BlockedTo({ children, roles: allowedRoles }: Props) {
  const {
    user: { roles: userRoles = [] }
  } = useAuth();

  const location = useLocation();

  if (!userRoles.length) return <Navigate to="/" state={{ from: location }} replace />;

  for (const userRole of userRoles) {
    if (allowedRoles.includes(userRole)) return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
