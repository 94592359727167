// assets
import { IconUserCircle, IconBriefcase, IconBuildingStore, IconClipboardList, IconFileText } from '@tabler/icons';

export type MenuId = 'reports' | 'distributors' | 'customers' | 'partners' | 'plans' | 'contracts' | '*';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Módulos',
  caption: 'Administração do sistema',
  type: 'group',
  children: [
    {
      id: 'customers',
      title: 'Usuários',
      type: 'item',
      icon: IconUserCircle,
      url: '/users/list'
    },
    {
      id: 'partners',
      title: 'Parceiros',
      type: 'item',
      icon: IconBriefcase,
      url: '/partners/list'
    },
    {
      id: 'distributors',
      title: 'Distribuidores',
      type: 'item',
      icon: IconBuildingStore,
      url: '/distributors/list'
    },
    {
      id: 'plans',
      title: 'Planos',
      type: 'item',
      icon: IconClipboardList,
      url: '/plans/list'
    },
    {
      id: 'contracts',
      title: 'Contratos',
      type: 'item',
      icon: IconFileText,
      url: '/contracts/list'
    }
  ]
};

export default pages;
