import axios from 'axios';
import { Cookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

// import mock from './mock';

function getAPIClient() {
  const cookie = new Cookies();
  const token = cookie.get('access_token');

  const api = axios.create({
    baseURL: process.env.REACT_APP_ARIDE_API_URL
  });

  api.interceptors.request.use((config) => {
    return config;
  });

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        cookie.set('access_token', null);
        cookie.remove('access_token');
        localStorage.removeItem('@APP/user');
        Navigate({ to: '/login', replace: true });
      }
      return Promise.reject(error);
    }
  );

  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return api;
}

const api = getAPIClient();

export default api;
