import styled from '@emotion/styled';

export const ProfileInfosWrapper = styled.div(() => ({
  backgroundColor: '#5e35b1',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  height: 'max-content',
  padding: '1rem 2rem',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: '#6f46c2',
    borderRadius: '50%',
    top: -50,
    right: -140
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: '#6f46c2',
    borderRadius: '50%',
    top: -125,
    right: -100,
    opacity: 0.5
  }
}));
