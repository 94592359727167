import { Alert, Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormContainer } from './partials/FormContainer';
import PasswordStrength from './partials/PasswordStrength';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Payload {
  password: string;
  password_confirm: string;
}

interface Props {
  onSubmit: (values: Record<string, unknown>) => void;
  isLoading: boolean;
  error: string;
  resetError: () => void;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/^(?!.*[£#" ]).+$/, 'Os caracteres #, £, " e espaços em branco não são permitidos')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])(?!.*[£#" ])/,
      'Use letras maiúsculas, minúsculas, números e símbolos'
    )
    .required('A senha é obrigatória'),
  password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais')
});

const initialRequirements = {
  size: false,
  uppercase: false,
  lowercase: false,
  numeric: false,
  special: false
};

export default function PasswordConfirm({ onSubmit, error, isLoading, resetError }: Props) {
  const [requirements, setRequirements] = useState(initialRequirements);
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, handleChange, touched, errors, values } = useFormik<Payload>({
    initialValues: {
      password: '',
      password_confirm: ''
    },
    validationSchema,
    onSubmit: ({ password }) => {
      onSubmit({ password });
    }
  });

  const getPasswordLevel = (requirements: typeof initialRequirements) => {
    return Object.values(requirements).filter(Boolean).length;
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const { password, password_confirm } = values;

  useEffect(() => {
    setRequirements({
      size: /^.{8,}$/.test(password),
      lowercase: /^(?=.*[a-z]).+$/.test(password),
      uppercase: /^(?=.*[A-Z]).+$/.test(password),
      numeric: /^(?=.*\d).+$/.test(password),
      special: /^(?=.*[\W_]).+$/.test(password)
    });
  }, [password]);

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <TextField
          id="password"
          name="password"
          label="Digite sua senha"
          variant="outlined"
          value={password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          required
          error={touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{
            marginBottom: -1
          }}
        />
        <PasswordStrength level={getPasswordLevel(requirements)} />
        <TextField
          id="password_confirm"
          name="password_confirm"
          label="Confirme sua senha"
          variant="outlined"
          value={password_confirm}
          onChange={handleChange}
          type="password"
          required
          error={touched.password_confirm && !!errors.password_confirm}
          helperText={touched.password_confirm && errors.password_confirm}
          sx={{
            marginTop: 1
          }}
        />
        {error ? (
          <Alert severity="error" onClose={resetError}>
            {error}
          </Alert>
        ) : null}
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading}
          sx={{
            height: 40
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Enviar'}
        </Button>
      </FormContainer>
    </form>
  );
}
