import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useAuth } from 'context/AuthContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const auth = useAuth();
  return useRoutes(auth.loading ? [] : [MainRoutes, AuthenticationRoutes]);
}