import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

export const FormContainer = styled(FormControl)`
  height: max-content;
  width: 100%;
  display: flex;
  width: 100%;
  gap: 1rem;
`;
