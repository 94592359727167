import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import useReducer from './userReducer';

// ==============================|| COMBINE REDUCER ||============================== //
const reducerForCombined = {
  customization: customizationReducer,
  user: useReducer
};

const reducer = combineReducers(reducerForCombined);

export default reducer;
