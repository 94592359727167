import { Grid, LinearProgress } from '@mui/material';

interface Props {
  level: number;
}

type Color = 'error' | 'warning' | 'success' | 'info';

const levelToLabel = ['Muito fraca', 'Fraca', 'Razoável', 'Forte', 'Muito forte', 'Excelente'];

const levelToColor: Color[] = ['error', 'error', 'warning', 'success', 'success', 'info'];

export default function PasswordStrength({ level }: Props) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item width={50 + level * 15}>
        <LinearProgress
          variant="determinate"
          value={100}
          color={levelToColor[level]}
          sx={{ height: '.75rem', borderRadius: '20px' }}
        />
      </Grid>
      <Grid item xs={3}>
        {levelToLabel[level]}
      </Grid>
    </Grid>
  );
}
