/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    <svg width="60" height="30" viewBox="0 0 117 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1053 119.367H0L37.8947 27.7881L48.6316 56.8407L22.1053 119.367Z" fill="#A547FF" />
      <path d="M116.212 120H91.5802L48.6328 0H72.0012L116.212 120Z" fill="#A547FF" />
    </svg>
  );
};

export default Logo;
