import { FormControl, styled } from '@mui/material';

export const FormContainer = styled(FormControl)`
  height: max-content;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;
`;
