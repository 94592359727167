export enum LocalStorageKey {
  USER = '@APP/user'
}

export function useLocalStorage() {
  const get = (key: LocalStorageKey) => {
    const result = localStorage.getItem(key as string);

    return result ? JSON.parse(result) : result;
  };

  return { get };
}
