import { useState, useRef, useEffect } from 'react';

import { redirect, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';

// project imports
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';

// assets
import { IconUser } from '@tabler/icons';
import { RootState } from 'store/type';
import { useAuth } from 'context/AuthContext';
import { LocalStorageKey, useLocalStorage } from 'hooks/useLocalStorage';
import { ProfileInfosWrapper } from './index.styles';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const localStorage = useLocalStorage();

  const theme = useTheme();
  const { logout } = useAuth();
  const customization = useSelector((state: RootState) => state.customization);
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const user = localStorage.get(LocalStorageKey.USER);

  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    logout(() => {
      redirect('/login');
    });
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event: any, index: number, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        label={<IconUser stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <ProfileInfosWrapper>
                    <Typography
                      sx={{
                        zIndex: 999,
                        fontSize: '1rem',
                        color: '#FAFAFA',
                        fontWeight: 400,
                        marginBottom: 1
                      }}
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#FAFAFA',
                        fontWeight: 200
                      }}
                    >
                      {user.email}
                    </Typography>
                  </ProfileInfosWrapper>
                  <Box sx={{ padding: '0 1rem .5rem 1rem' }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                        selected={selectedIndex === 1}
                        onClick={(event: any) => handleListItemClick(event, 1, '/profile')}
                      >
                        <ListItemIcon>
                          <IconUser stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Meu perfil</Typography>} />
                      </ListItemButton>
                      <Button
                        onClick={handleLogout}
                        variant="outlined"
                        sx={{
                          borderRadius: '999rem',
                          borderColor: '#ECECEC',
                          marginTop: '.5rem'
                        }}
                      >
                        Sair
                      </Button>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
