import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(255).required('Campo obrigatório'),
  username: Yup.string().required('Campo obrigatório'),
  mobile_phone: Yup.string()
    .matches(/\((\d{2})\) (\d{5})-(\d{4})/, 'O número precisa estar no formato correto')
    .required('Campo obrigatório'),
  birthdate: Yup.date().required('Campo obrigatório')
});
