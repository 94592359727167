import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, ThemeOptions } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import { RootState } from 'store/type';
import { AuthProvider } from 'context/AuthContext';
import { SnackbarProvider } from 'notistack';
// ==============================|| APP ||===============

function App() {
  const customization = useSelector((state: RootState) => state.customization);

  return (
    <AuthProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization as ThemeOptions)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
