import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import Logo from 'components/Logo';
import PasswordConfirm from '../auth-forms/PasswordConfirm';
import { useEffect, useState } from 'react';
import api from 'services/api';
import SuccessFeedback from '../auth-forms/partials/SuccessFeedback';

const TIME_TO_REDIRECT_IN_SECONDS = 3 * 1000;

export default function Activate() {
  const theme = useTheme();
  const { activation_code } = useParams();
  const navigate = useNavigate();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [finished, setFinished] = useState(false);

  const handleSubmit = async ({ password }: Record<string, unknown>) => {
    setIsLoading(true);

    try {
      await api.post('/Authorize/activate-account', {
        password,
        activation_code
      });

      setFinished(true);
    } catch (e: any) {
      let errorMessage = 'Algo deu errado, tente novamente mais tarde.';
      if (e.response.data) errorMessage = e.response.data.notifications[0].message;
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (finished)
      setTimeout(() => {
        navigate('/login');
      }, TIME_TO_REDIRECT_IN_SECONDS);
  }, [finished]);

  const resetError = () => setError('');

  if (finished)
    return (
      <SuccessFeedback
        title="Conta ativada com sucesso"
        description="Você será redirecionado para a página de login!"
      />
    );

  return (
    <AuthWrapper1>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? 'column-reverse' : 'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? 'h3' : 'h2'}
                          >
                            Ativar sua conta
                          </Typography>
                          <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                            Crie uma senha e envie para ativar sua conta
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordConfirm
                      onSubmit={handleSubmit}
                      isLoading={isLoading}
                      error={error}
                      resetError={resetError}
                    />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
}
