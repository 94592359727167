// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconBorderAll, IconCheckupList } from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBorderAll,
  IconCheckupList
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilitários',
  type: 'group',
  children: [
    {
      id: 'util-typography',
      title: 'Tipografia',
      type: 'item',
      url: '/utils/util-typography',
      icon: icons.IconTypography,
      breadcrumbs: false
    },
    {
      id: 'util-simpleDatatable',
      title: 'Tabela Simples',
      type: 'item',
      url: '/utils/datatable',
      icon: icons.IconBorderAll,
      breadcrumbs: false
    },
    {
      id: 'util-color',
      title: 'Cores',
      type: 'item',
      url: '/utils/util-color',
      icon: icons.IconPalette,
      breadcrumbs: false
    },
    {
      id: 'util-shadow',
      title: 'Shadow',
      type: 'item',
      url: '/utils/util-shadow',
      icon: icons.IconShadow,
      breadcrumbs: false
    },
    {
      id: 'util-form',
      title: 'Formulário',
      type: 'item',
      url: '/utils/form',
      icon: icons.IconCheckupList,
      breadcrumbs: false
    },
    {
      id: 'icons',
      title: 'Icones',
      type: 'collapse',
      icon: icons.IconWindmill,
      children: [
        {
          id: 'tabler-icons',
          title: 'Tabler Icons',
          type: 'item',
          url: '/icons/tabler-icons',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default utilities;
