import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const PhoneMaskInput = forwardRef<HTMLElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    />
  );
});
