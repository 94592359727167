import { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Signin } from 'services/authentication';

interface AuthContextType {
  user: any;
  loading: Boolean;
  signin: (email: string, password: string, callback: VoidFunction) => void;
  logout: (callback: VoidFunction) => any;
  cookies: any;
}

let AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);

  useEffect(() => {
    const user = localStorage.getItem('@APP/user');
    const token = cookies['access_token'];
    if (user && token) {
      setUser(JSON.parse(user));
    }
    setLoading(false);
  }, []);

  const signin = async (email: string, password: string, callback: VoidFunction) => {
    const response = await Signin(email, password);
    if (response?.data) {
      setCookie('access_token', response.data.token);

      localStorage.setItem(
        '@APP/user',
        JSON.stringify({
          email,
          roles: response.data.roles,
          name: response.data.name
        })
      );
      callback();
    }
    return response;
  };

  const logout = () => {
    setUser(null);
    removeCookie('access_token');
    localStorage.removeItem('@APP/user');
    localStorage.removeItem('@APP/token');
    return true;
  };

  const value = { user, signin, logout, loading, cookies };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
