import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Activate from 'views/pages/authentication/authentication/Activate';
import ResetPassword from 'views/pages/authentication/authentication/ResetPassword';
import { RouteObject } from 'react-router';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthForgot = Loadable(lazy(() => import('views/pages/authentication/authentication/Forgot')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes: RouteObject = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '/login/activate/:activation_code',
      element: <Activate />
    },
    {
      path: '/forgot',
      element: <AuthForgot />
    },
    {
      path: '/reset-password/:activation_code',
      element: <ResetPassword />
    }
  ]
};

export default AuthenticationRoutes;
