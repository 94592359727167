// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuSections from 'menu-items';
import { Role } from 'routes/access/AllowedTo';
import { MenuId } from 'menu-items/pages';
import { useAuth } from 'context/AuthContext';

const mapRoleToMenuIds: {
  [key in Role]: MenuId[];
} = {
  ADMIN: ['*'],
  FINANCIAL: ['customers', 'partners', 'plans', 'contracts', 'distributors', 'reports'],
  COMMERCIAL: ['customers', 'partners', 'plans', 'contracts', 'distributors', 'reports'],
  OPERATOR: ['plans', 'partners', 'contracts', 'distributors'],
  PARTNER: ['reports'],
  DISTRIBUTOR: ['reports'],
  USER: []
};

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const {
    user: { roles: userRoles }
  } = useAuth();

  const getAllowedItemsByRoles = (roles: Role[] = []) => {
    const roleAllowedPageIds = roles.reduce((acc, role) => [...acc, ...mapRoleToMenuIds[role]], [] as MenuId[]);

    const [dashboard, pages] = menuSections;

    const allowedPages = (pages.children as any[]).filter((item) => {
      if (roleAllowedPageIds.includes('*')) return true;

      return roleAllowedPageIds.includes(item.id as MenuId);
    });

    pages.children = allowedPages;

    return [dashboard, pages];
  };

  const navItems = getAllowedItemsByRoles(userRoles).map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Erro no menu items
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
