import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, Menu } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import { useDispatch, useSelector } from 'react-redux';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { RootState } from 'store/type';
import { IMenu } from 'components/extended/Breadcrumbs';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }: { menu: IMenu; level: number }) => {
  const theme = useTheme();
  const customization = useSelector((state: RootState) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const openCollapse = Boolean(anchorEl);
  const handleClickCollapse = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  // menu collapse & item
  const menus = menu.children?.map((item: IMenu) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  // menu pop & item
  const menus_pops = menu.children?.map((item) => {
    switch (item.type) {
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} type="menuItem" />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: customization.opened ? `${level * 24}px` : 1
        }}
        selected={selected === menu.id}
        onClick={
          customization.opened
            ? handleClick
            : () => {
                selected;
              }
        }
        onMouseOver={handleClickCollapse}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }} id={menu.id}>
          {menuIcon}
        </ListItemIcon>
        {customization.opened && (
          <>
            <ListItemText
              primary={
                <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                  {menu.title}
                </Typography>
              }
              secondary={
                menu.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {menu.caption}
                  </Typography>
                )
              }
            />
            {open ? (
              <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            ) : (
              <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            )}
          </>
        )}
      </ListItemButton>
      {customization.opened && (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                position: 'relative',
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  left: '32px',
                  top: 0,
                  height: '100%',
                  width: '1px',
                  opacity: 1,
                  background: theme.palette.primary.light
                }
              }}
            >
              {menus}
            </List>
          </Collapse>
        </>
      )}
      {!customization.opened && (
        <>
          <Menu
            id={menu.id}
            anchorEl={anchorEl}
            open={openCollapse}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': menu.id,
              onMouseLeave: handleClose
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onMouseLeave={handleClose}
          >
            <List component="div" disablePadding>
              {menus_pops}
            </List>
          </Menu>
        </>
      )}
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  type: PropTypes.string,
  level: PropTypes.number
};

export default NavCollapse;
