import MainCard from 'components/cards/MainCard';
import { BasicTabs } from './partials/tabs';

export function ProfilePage() {
  return (
    <MainCard title="Perfil">
      <BasicTabs />
    </MainCard>
  );
}
